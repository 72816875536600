:root {
  --panelHeight: 75;
  --panelHeightPx: calc(var(--panelHeight) * 1px);
  --contentWidth: 960px;
}
@media screen and (max-height: 700px) {
  :root {
    --panelHeight: 50;
  }
}
@media screen and (max-height: 400px) {
  :root {
    --panelHeight: 35;
  }
}
@media screen and (max-width: 960px) {
  :root {
    --contentWidth: calc(100vw - 50px);
  }
}
